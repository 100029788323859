import {
  BaseLayout,
  Helmet,
  Interfaces,
  Link,
  PreStyledComponents,
  Theme,
  media
} from "@life-without-barriers/react-components"
import Form, {
  FieldsProps,
  FormFields
} from "@life-without-barriers/shared-contact-form"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Site, getGatsbyImage } from "@life-without-barriers/gatsby-common"
import styled, { ThemeProps } from "styled-components"

import { DataFormatters } from "@life-without-barriers/shared-contact-form"
import React from "react"
import { graphql } from "gatsby"

const { disabilityTheme } = Theme
const { Container, StandardButton } = PreStyledComponents

const { Name, ContactMethodToggleField, MessageTextArea } = FormFields

const LogoText = styled.div`
  font-size: 12px;
  width: 8em;
  color: ${(props: ThemeProps<Interfaces.Theme>) => props.theme.dark};
  line-height: 1.25;

  ${media.notSmall`
    font-size: 16px;
  `}
`

const HeaderLogo = styled(GatsbyImage)`
  width: 48px !important;
  height: 48px !important;

  ${media.notSmall`
    width: 64px !important;
    height: 64px !important;
  `}
`

const Banner = styled.div`
  background: radial-gradient(circle at 120%, #133f62, #247bbf);
  padding-top: 3rem;
  padding-bottom: 3rem;

  ${media.large`
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  `}
`

const BannerInner = styled.div`
  width: 85%;
`

const BannerTopText = styled.p`
  font-size: 14px;
`

const BannerBottomText = styled.p`
  font-size: 18px;
`

const BannerTitle = styled.h1`
  font-size: 42px;
  line-height: 1em;

  ${media.large`
    font-size: 72px;
  `}
`

const LightBlueBackground = styled.div`
  background-color: rgba(91, 156, 200, 0.1);
`

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    careLogo: IGatsbyImageData
    metaImage: IGatsbyImageData
  }
}

const CopPage = ({
  location,
  data: {
    site: { siteMetadata },
    careLogo,
    metaImage
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`International CARE Community of Practice | Hosted by ${siteMetadata.title}`}
        description="This discussion group is a space to share and discuss ideas, experiences, articles and other resources related to the CARE implementation journey."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={metaImage}
      />
      <BaseLayout theme={disabilityTheme}>
        <Container>
          <div className="flex items-center ma2 ma3-l">
            <HeaderLogo
              image={getGatsbyImage(careLogo)}
              className="mr2"
              alt=""
            />
            <LogoText className="fw7 word-wrap">
              International community of practice
            </LogoText>
          </div>
        </Container>
        <Banner className="tc color-lwb-white flex flex-column items-center">
          <BannerInner className="flex flex-column">
            <BannerTopText className="ttu pb3 dn db-l">
              Hosted by Life Without Barriers
            </BannerTopText>
            <BannerTitle className="fw8 pb3">
              <div>International CARE</div>
              <div>Community of Practice</div>
            </BannerTitle>
            <BannerBottomText>
              Sharing the journey toward implementation of CARE
            </BannerBottomText>
          </BannerInner>
        </Banner>
        <LightBlueBackground>
          <Container className="pt4 ph3 pv5-l ph4-ns flex flex-wrap">
            <div className="w-100 w-60-l mt0 mt3-ns pr4-l">
              <h2 className="ts-display-3 fw8">
                Community of practice discussion group
              </h2>
              <p>
                The International CARE Community of Practice Discussion Group is
                a space to share and discuss ideas, experiences, articles and
                other resources related to the CARE implementation journey.
              </p>
              <p>
                Members of the group are drawn from around the world and have a
                diverse range of experience, organisational contexts and
                jurisdictional requirements. They represent CARE organisations
                ranging in size from the local to the national and at almost
                every stage of the implementation journey.
              </p>
              <p>
                If you are implementing CARE and would like to join the group,
                please click the link below to request access.
              </p>
              <h2 className="ts-display-3 pt4 fw8">
                Why join the CARE community of practice?
              </h2>
              <p>
                No matter where you are in your CARE implementation journey, the
                International CARE Community of Practice provides a forum to:
              </p>
              <p>
                <ol>
                  <li>
                    Connect with other people and organisations implementing
                    CARE
                  </li>
                  <li>
                    Access the latest research, practice wisdom and other
                    resources
                  </li>
                  <li>
                    Discuss barriers to implementation and share solutions
                  </li>
                </ol>
              </p>
              <div className="dib mt4">
                <Link
                  className="no-underline"
                  to="https://www.linkedin.com/groups/13724719/"
                >
                  <StandardButton shade="dark">
                    Visit the linkedin group
                  </StandardButton>
                </Link>
              </div>
            </div>
            <FormWrapper className="w-100 w-40-l">
              <Form
                fieldFactory={(fieldsProps: FieldsProps) => [
                  <Name key="Name" {...fieldsProps} />,
                  <ContactMethodToggleField
                    key="ContactMethodToggleField"
                    initialContactMethod="phone"
                    {...fieldsProps}
                  />,
                  <MessageTextArea key="MessageTextArea" {...fieldsProps} />
                ]}
                formId="care-cop"
                formKind="care-cop"
                formTitle="Talk to a CARE Specialist"
                formSubtitle="To learn more about the CARE community of practice, enter your details."
                initialValues={{
                  contactMethod: "phone"
                }}
                formDataFormatter={DataFormatters.nestContactDetailsFormatter}
              />
            </FormWrapper>
          </Container>
          <ClippedOuter>
            <ClippedInner>
              <div className="bg-black">
                <Container className="white pv5 ph3">
                  <div className="flex flex-column flex-row-reverse-ns justify-between-ns mb4">
                    <div className="flex flex-column-ns items-end-ns justify-end-ns">
                      <FooterTitle className="fw8 w-70-ns tr-ns">
                        International CARE Community of Practice
                      </FooterTitle>
                    </div>
                    <div>
                      <FooterEmail className="b pt4 pt0-ns">
                        <StyledLink
                          className="pt2 db color-lwb-white link"
                          to="mailto:stride@lwb.org.au"
                        >
                          stride@lwb.org.au
                        </StyledLink>
                      </FooterEmail>
                      <StyledLink
                        className="pt2 db color-lwb-white fw6 underline"
                        to="/our-approach/child-youth-and-family/care-creating-conditions-for-change/"
                      >
                        CARE at Life Without Barriers
                      </StyledLink>
                      <StyledLink
                        className="pt1 db color-lwb-white fw6 underline"
                        to="https://rccp.cornell.edu/care/care_model.html"
                      >
                        The CARE Program Model - Cornell University
                      </StyledLink>
                    </div>
                  </div>
                  <div className="flex flex-column flex-row-ns justify-between-ns bt pt4">
                    <ReconciliationText className="w-60-ns">
                      In the spirit of Reconciliation, Life Without Barriers
                      acknowledges Aboriginal and Torres Strait Islander peoples
                      as the Traditional Owners and Custodians of this country,
                      and acknowledges their connection to land, water and
                      community. We pay our respect to them, their cultures and
                      customs, and to Elders past, present and emerging.
                    </ReconciliationText>
                    <CopyrightText className="mt3">
                      © 2019 Life Without Barriers. All rights reserved.
                    </CopyrightText>
                  </div>
                </Container>
              </div>
            </ClippedInner>
          </ClippedOuter>
        </LightBlueBackground>
      </BaseLayout>
    </div>
  )
}

export default CopPage

const FormWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;

  ${media.large`
    margin-top: 1rem;
    margin-bottom: 0rem;
  `}
`

const ClippedInner = styled.div`
  clip-path: polygon(0 7%, 100% 8%, 100% 100%, 0% 100%);

  ${media.notSmall`
    clip-path: polygon(0 6%, 100% 8%, 100% 100%, 0% 100%);
  `}
`

const ClippedOuter = styled.div`
  background-color: #b2d1e5;
  clip-path: polygon(0 5%, 100% 6%, 100% 100%, 0% 100%);

  ${media.notSmall`
    clip-path: polygon(0 4%, 100% 6%, 100% 100%, 0% 100%);
  `}
`

const CopyrightText = styled.div`
  font-size: 12px;
`

const ReconciliationText = styled.div`
  font-size: 12px;
  line-height: 1.25rem;
`

const FooterTitle = styled.div`
  font-size: 24px;
  line-height: 2rem;
`

const FooterEmail = styled.div`
  font-size: 18px;
`

const StyledLink = styled(Link)`
  :hover {
    color: ${(props: ThemeProps<Interfaces.Theme>) => props.theme.dark};
  }

  :focus {
    color: ${(props: ThemeProps<Interfaces.Theme>) => props.theme.dark};
    outline: 1px dashed
      ${(props: ThemeProps<Interfaces.Theme>) => props.theme.xdark};
  }
`

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    careLogo: file(relativePath: { regex: "/care-logo.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 64, quality: 100, layout: FIXED)
      }
    }
    metaImage: file(relativePath: { regex: "/OG-image-care.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 800, quality: 90)
      }
    }
  }
`
